import jsxElem from "jsx-no-react";

function htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

const icon = async (icon, baseElement) => {
  try{
    const { default: feather } = await import(/* webpackChunkName: "feather-icons" */ 'feather-icons');

    baseElement.appendChild(htmlToElement(feather.icons[icon].toSvg()))
  }
  catch(e) {
    console.log(e)
  }
};

export default (props) => {
  const baseElement = <div></div>;

  icon(props.name, baseElement);
  return baseElement;
}
