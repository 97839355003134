import jsxElem from "jsx-no-react";

import Pdv, {rnd, getData, elementResult} from './Pdv';

const resolvePdv = () => {
  const {
    tgs,
    th,
    tc,
    tas
  } = getData(document.datapdv3);

  const wca = th - tc;

  const xc = rnd(tas * Math.sin(wca * Math.PI / 180));

  const etas = rnd(tas * Math.cos(wca * Math.PI / 180));

  const lc = rnd(tgs - etas);

  const v = rnd(Math.sqrt(Math.pow(xc, 2) + Math.pow(lc, 2)));

  const w = (() => {
    if(xc > 0)
  		return tc + (90 + Math.asin(lc / v) * 180/Math.PI);

    return tc + (-(90 + Math.asin(lc / v) * 180/Math.PI));
  })();

  const result = elementResult({
    xc: xc,
    lc: lc,
    wca: wca,
    v: v,
    w: w
  });

  document.querySelector('#pdv3 .result ul').replaceWith(result);
};

export default <Pdv id="pdv3" title="Pdv 3" pdvType="3" cb={resolvePdv} />
