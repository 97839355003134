import jsxElem from "jsx-no-react";

import Pdv, {rnd, getData, elementResult} from './Pdv';

const resolvePdv = () => {
  const {
    v,
    w,
    tc,
    ngs
  } = getData(document.datapdv4);

  const xc = rnd(v * Math.sin((w - tc) * Math.PI / 180));

  const lc = rnd(-v * Math.cos((tc - w) * Math.PI / 180));

  const etas = ngs - lc;

  const wca = Math.atan(xc / etas) * 180 / Math.PI;

  const th = tc + wca;

  const tas = rnd(etas / Math.cos(wca * Math.PI/180));

  const result = elementResult({
    xc: xc,
    lc: lc,
    wca: wca,
    th: th,
    etas: etas,
    tas: tas
  });

  document.querySelector('#pdv4 .result ul').replaceWith(result);
};

export default <Pdv id="pdv4" title="Pdv 4" pdvType="4" cb={resolvePdv} />
