import jsxElem, { render } from "jsx-no-react";

import runtime from 'serviceworker-webpack-plugin/lib/runtime';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

window.onload = () => {
  render(<App />, document.getElementById('root'))
};

if (
  'serviceWorker' in navigator
  && (
    window.location.protocol === 'https:'
    || window.location.hostname === 'localhost'
  )
) {
    runtime.register().then(reg => {
      reg.addEventListener('updatefound', () => {
        // A wild service worker has appeared in reg.installing!
        const newWorker = reg.installing;
        newWorker.addEventListener('statechange', () => {
          // Has network.state changed?
          switch (newWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                // new update available
                document.getElementById('reload').style.visibility = 'visible';

                document.getElementById('reload').addEventListener('click', function(){
                  newWorker.postMessage({ action: 'skipWaiting' });
                });
              }
              // No update available
              break;
          }
        });
      });
    });
    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', function () {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }
