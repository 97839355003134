import jsxElem from "jsx-no-react";

import Pdv, {rnd, getData, elementResult} from './Pdv';

const resolvePdv = () => {
  const {
    v,
    w,
    tc,
    tas
  } = getData(document.datapdv1);

  const xc = rnd(v * Math.sin((w - tc) * Math.PI / 180));

  const lc = rnd(-v * Math.cos((tc - w) * Math.PI / 180));

  const wca = Math.asin(xc / tas) * 180 / Math.PI;

  const etas = rnd(tas * Math.cos(wca * Math.PI / 180));

  const th = rnd(tc + wca);

  const gs = rnd(etas + lc);

  const result = elementResult({
    xc: xc,
    lc: lc,
    wca: wca,
    etas: etas,
    th: th,
    gs: gs
  });

  document.querySelector('#pdv1 .result ul').replaceWith(result);
};

export default (props) => {
  return <Pdv id="pdv1" title="Pdv 1" pdvType="1" {...props} cb={resolvePdv} />;
}
