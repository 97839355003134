import jsxElem from "jsx-no-react";

import PdvForm from "./Form";

export const getData = (dataElm) => {
  const data = {};
  dataElm.querySelectorAll('input').forEach(
    e => {data[e.name] = parseFloat(e.value);}
  );

  return data;
}

export const rnd = (value) => Math.round(value*100)/100;

export const elementResult = (result) => {
  return <ul>
    {
      Object.keys(result)
        .map(key => <li  className="form-group d-flex align-items-stretch">
          <label className="col-sm-4 col-form-label">{key}:</label>
           <span className="col-sm-8">{rnd(result[key]).toString()}</span>
          </li>
        )
    }
  </ul>;
}

export default (props) => {
  const cb = props.cb;
  delete props.cb;

  const active = props.active ? 'pdvActive' : '';

  const resolver = () => {
    cb();
    document.querySelector('.pdvActive button').scrollIntoView(true);
    window.scrollBy(0, -100);
  };

  return <div className={`col pdv ${active}`}>
    <header className="text-center">
      <h3>{props.title}</h3>
    </header>
    <section>
      <PdvForm type={props.pdvType} name={`data${props.id}`} cb={resolver} />
    </section>
  </div>;
}
