import jsxElem from "jsx-no-react";

import Icon from "../icons";

const BaseInput = (props) => (<input className="form-control" {...props} />);
const InputNumber = (props) => (
  <div className="form-group d-flex align-items-stretch">
    <label htmlFor={props.name} className="col-sm-4 col-form-label">
      {props.name}:
    </label>
    <div className="col-sm-8">
      <BaseInput type="number" {...props}/>
    </div>
  </div>
);

const TAS = () => <InputNumber name="tas" />;
const TC = () => <InputNumber name="tc" />;
const TH = () => <InputNumber name="th" />;
const W = () => <InputNumber name="w" />;
const V = () => <InputNumber name="v" />;
const NGS = () => <InputNumber name="ngs" />;
const TGS = () => <InputNumber name="tgs" />;

export default function (props) {
  const type = props.type;
  const cb = props.cb;
  const Btn = <button
      className="btn btn-lg btn-primary"
      name="send"
      value="Calcola"
      onclick={cb}
    >
      <Icon name="send" />
    </button>;

  delete props.type;
  delete props.cb;



  switch(type) {
    case '10':
      return <div className="col">
  <div className="row">
    <div className="col">
      1 of 2
    </div>
    <div className="col">
      2 of 2
    </div>
  </div>
  <div className="row">
    <div className="col">
      1 of 3
    </div>
    <div className="col">
      2 of 3
    </div>
    <div className="col">
      3 of 3
    </div>
  </div>
</div>;
    case '1':
      return <div className="row">
        <div className="col-md-5 col-sm-12 text-center">
          <form className="form d-flex flex-column align-items-stretch" {...props}>
            <V />
            <W />
            <TC />
            <TAS />
          </form>
        </div>
        <div className="col-md-2 col-sm-12 d-flex align-items-stretch"><Btn /></div>
        <div className="result col-md-5 col-sm-12 text-center"><ul></ul></div>
      </div>;

    case '3':
      return <div className="row">
        <div className="col-md-5 col-sm-12 text-center">
          <form className="form d-flex flex-column align-items-stretch" {...props}>
            <TC />
            <TH />
            <TAS />
            <TGS />
          </form>
        </div>
        <div className="col-md-2 col-sm-12 text-center"><Btn /></div>
        <div className="result col-md-5 col-sm-12 text-center"><ul></ul></div>
      </div>;
    case '4':
      return <div className="row">
        <div className="col-md-5 col-sm-12 text-center">
          <form className="form d-flex flex-column align-items-stretch" {...props}>
            <V />
            <W />
            <TC />
            <NGS />
          </form>
        </div>
        <div className="col-md-2 col-sm-12 text-center"><Btn /></div>
        <div className="result col-md-5 col-sm-12 text-center"><ul></ul></div>
      </div>;
    default:
      return 'NO!';
  }
}
