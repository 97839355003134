import jsxElem from "jsx-no-react";

import Icon from "./icons";

import './App.css';

import {Pdv, Pdv1, Pdv3, Pdv4} from './pdv';

function selectPdV(e) {
  const n = e.target.parentNode.getAttribute('data-pdv');

  document.querySelector('#main-menu ul li span.active')
    .classList
    .remove('active');

  e.target.classList.add('active');

  document.querySelectorAll('.pdv')
    .forEach(
      elm => {
        if(elm.id == `pdv${n}`) {
            elm.style.display = '';
            elm.classList.add('pdvActive');
        }
        else {
          elm.style.display = 'none';
          elm.classList.remove('pdvActive');
        }
      }
    );
    mobileMenu();
    window.scrollTo(0,0);
}

function mobileMenu() {
  const mm = document.querySelector('#root #navbarCollapse');

  if(mm.classList.contains('open'))
    mm.classList.remove('open');
  else
    mm.classList.add('open');
}

function App() {

  return (
    <div className="container">
      <header className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <span className="navbar-brand">Risolutore Problemi del Vento <ion-icon name="cellular"></ion-icon></span>
        <button
          onclick={mobileMenu}
          className="d-block d-sm-none btn btn-outline-light"
        >
          <Icon name="menu" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <nav id="main-menu">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item" data-pdv="1" onclick={selectPdV}>
                <span className="nav-link active">PdV 1</span>
              </li>
              <li className="nav-item" data-pdv="3" onclick={selectPdV}>
                <span className="nav-link">PdV 3</span>
              </li>
              <li className="nav-item" data-pdv="4" onclick={selectPdV}>
                <span className="nav-link">PdV 4</span>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <main role="main" id="main" className="row">
        <Pdv1 active={true} />
        <Pdv3 style={{display: 'none'}} />
        <Pdv4 style={{display: 'none'}} />
      </main>
      <section>
        <div>
          <p>
            Sviluppato durante gli anni passati all' ITAER (Istituto Tecnico Aeronautico) Francesco De Pinedo, questo script risolve il primo, terzo e quarto problema del vento, utile sia per la navigazione aerea che per quella marittima.
          </p>
          <p>
            <a rel="license" href="//creativecommons.org/licenses/by-nc/2.5/it/"><img alt="Licenza Creative Commons" style="border-width:0" src="//i.creativecommons.org/l/by-nc/2.5/it/88x31.png" /></a><br />This opera is licensed under a <br />
            <a rel="license" href="//creativecommons.org/licenses/by-nc/2.5/it/">Creative Commons Attribuzione - Non commerciale 2.5 Italia License</a>.
          </p>
          <p>
            Segnalaci eventuali errori! <br /> matteo [a t] barison [d o t] me
          </p>
        </div>
      </section>
      <footer>
        <div className="text-center">
          Made with ❤️ by <a href='https://matteobarison.com'><strong>matte1</strong></a>
        </div>
        <div id="version">
          V.{VERSION} 25/08/2019
        </div>
      </footer>
      <div id="reload">
        Nuova versione disponibile, clicca qui!
      </div>
    </div>
  );
}

export default App
